export const onClientEntry = () => {}
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // Always scroll to the top on page change
  window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0); 
  return false;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Always scroll to the top on page change
  console.log('shouldUpdateScroll');
  window.scrollTo(0, 0);
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 0); 
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 5); 
  return false;
};