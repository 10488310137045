import netlifyIdentity from "netlify-identity-widget"

//  --------------------------------------------------------------------
export const isBrowser = () => typeof window !== "undefined"

export const initAuth = () => {
  if (isBrowser()) {
    // window.netlifyIdentity = netlifyIdentity
    // You must run this once before trying to interact with the widget
    netlifyIdentity.init()
    console.log("initialized identity!")
  }
}

//  --------------------------------------------------------------------

export const getUser = () => {
  console.log(
    "getUser() isBrowser & localStorage user ? ",
    isBrowser(),
    window.localStorage.getItem("netlifyUser")
  )

  return isBrowser() && window.localStorage.getItem("netlifyUser")
    ? JSON.parse(window.localStorage.getItem("netlifyUser"))
    : {}
}

const setUser = (user) => {
  console.log("setUser()", user)
  window.localStorage.setItem("netlifyUser", JSON.stringify(user))
}

//  --------------------------------------------------------------------

export const handleLogin = (callback) => {
  console.log("handleLogin()")
  if (isLoggedIn()) {
    callback(getUser())
  } else {
    console.log("not logged in yet...")
    netlifyIdentity.open()
    console.log("open me...")
    netlifyIdentity.on("login", (user) => {
      setUser(user)
      callback(user)
      netlifyIdentity.close()
    })
  }
}

//  --------------------------------------------------------------------

export const isLoggedIn = () => {
  if (!isBrowser()) return false
  const user = netlifyIdentity.currentUser()
  return !!user
}

export const logout = (callback) => {
  netlifyIdentity.logout()
  console.log("Logging out...")
  netlifyIdentity.on("logout", () => {
    setUser({})
    callback()
  })
}
