import React from "react"

import NavLink from "./navLink"

interface NavProps {
  isOpen: boolean
  onNavItemClick: () => void
}

const navigationList = [
  {
    name: "What We Do",
    route: "what-we-do",
  },
  {
    name: "Stories & news",
    route: "stories-and-news",
  },
  {
    name: "About us",
    route: "about-us",
  },
  {
    name: "Contact",
    route: "contact",
  },
]

const Nav = ({ isOpen, onNavItemClick }: NavProps) => {
  return (
    <nav className={`navigation ${isOpen ? "opened" : "closed"}`}>
      <div className={`navigation__grid`}>
        <div className="navigation__grid--colummn">
          {navigationList.map(({ name, route }) => (
            <NavLink
              key={name}
              onClick={onNavItemClick}
              name={name}
              route={route}
              className="main"
            />
          ))}
        </div>
        <div className="navigation__grid--colummn ctas  text-right">
          <div className="rhs-grid">
            <div className="rhs-grid__cell  rhs-grid__cell--what-we-do">
              <NavLink
                onClick={onNavItemClick}
                name="Tap Finder"
                route="tap-finder"
                className="what-we-do"
              />
            </div>
            <div className="rhs-grid__cell  rhs-grid__cell--partner-login">
              <NavLink
                onClick={onNavItemClick}
                name="Partner Login"
                route="asset-library"
                className="partner-login main"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
