import React from "react"

import { Link } from "gatsby-link"

interface NavLinkProps {
  className?: string
  name: string
  onClick: () => void
  route: string
}

const NavLink = ({ className, name, onClick, route }: NavLinkProps) => {
  return (
    <Link
      activeClassName="navigation__link--active"
      onClick={onClick}
      className={`navigation__link typography__ff--flamaMedium  typograhpy__element--navigation ${className}`}
      to={`/${route}`}
      // activeStyle={{ textDecoration: "underline" }}
    >
      {name}
    </Link>
  )
}

export default NavLink
