import React from "react"

import "./styles.scss"

import { HamburgerIcon } from "../../svgs"

interface HamburgerProps {
  isOpen: boolean
  onClick: () => void
}

const Hamburger = ({ onClick, isOpen }: HamburgerProps) => {
  return (
    <button
      type="button"
      className="hamburger"
      onClick={onClick}
      aria-label="Mobile Menu Toggle"
    >
      <HamburgerIcon isOpen={isOpen} />
    </button>
  )
}

export default Hamburger
